import React from "react";

export const About = (props) => {
  return (
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            {props.data.Img && <img src={props.data.Img} className="img-responsive" id="" alt="" />}
          </div>
          <div className={props.data.Img ? "col-xs-12 col-md-6" : "col-xs-12 col-md-12"}>
            <div className="about-text">
              <h2>{props.data.About}</h2>
              <div className="col-xs-12 col-md-4">
                {props.data.KnowMore && <img src={props.data.KnowMore.image} className="img-responsive know-more-img" alt="smart-city"/>}
              </div>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              {props.data.KnowMore &&  
              <a
                href={props.data.KnowMore.link}
                className="btn btn-custom btn-lg page-scroll"
              >
              {props.data.KnowMore.title}
              </a>}
              {props?.data.Whys && <><h3>{props.data.Whys.Why_Choose}</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Whys.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Whys.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                </div></>}
              {/* <div style={{
                paddingTop: '140px'
              }}></div> */}
              {props?.data.Whys2 && <><h3>{props.data.Whys2.Why_Choose}</h3>
                <div className="list-style">
                  {props?.data.paragraph2 && <p>{props.data.paragraph2}</p>}
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Whys2.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Whys2.Why2?.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </>}
            </div>
          </div>
          {props.data.Icons && <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  {props.data
                    ? props.data.Icons?.map((d, i) => (
                      <li key={`${d.icon}-${i}`}>
                        <a href={d.link} target="_blank" rel="noreferrer" title={d.msg}>
                          <i className={d.icon}></i>
                        </a>
                      </li>
                    ))
                    : "loading"}
                </ul>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </section>
  );
};
