import React, { createContext, useContext, useEffect, useState } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('light');

  const changeLanguage = (lang) => {
    if (lang === "pt"){
      localStorage.setItem("lang", "pt");
      setLanguage("pt");
    }else{
      localStorage.setItem("lang", "en");
      setLanguage("en");
    }
  };

  useEffect(()=>{
    console.log("navigator.language:", navigator.language)
    console.log("localStorage.getItem('lang'):", localStorage.getItem("lang"))
    if (localStorage.getItem("lang") === null)
    {
      if(navigator.language.includes("pt")){
       setLanguage("pt");
      }else{
        setLanguage("en");
      }
    }
    else
    {
      if (localStorage.getItem("lang") === "pt"){
       setLanguage("pt");
      }else{
        setLanguage("en");
      }
    }  
  },[])


  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  return useContext(LanguageContext);
};

export { LanguageProvider, useLanguage };
export default LanguageContext;