import React from "react";
import { useLanguage } from "../../context/language";
import SEO from "../SEO";
import Leandro from "./leandro";

export default function MsgsTemplates() {
  const { language } = useLanguage();
  return (
    <section id="blog">
      <SEO
        title={"Oktopus | Blog"}
        description={"USP(TR-369) and CWMP(TR-069) messages templates"}
        keywords={
          "USP,TR-369,CWMP,TR-069,Messages Templates,Oktopus Controller,TR-181,TR-098"
        }
      />
      <div className="container">
        <div className="row">
          {language === "en" ? (
            <div className="blog-text">
              <div className="about-text">
                <h2>USP and CWMP Messages Templates</h2>
              </div>
              <p>
              Despite the definition of message standards and data structures that were created by the Broadband Forum, each equipment manufacturer has, for the most part, a set of exclusive parameters that are used to configure and monitor the device or else implements the USP/CWMP such in a way that does not strictly follow the standard, returning information and performing important actions in ways that are different from those intended.
              </p>
              <p>
              With this in mind, it becomes a difficult task to map the parameters of each supplier and still maintain the agnosticity of the platform and workflow.
              Therefore, Oktopus developed a module of reusable templates that allows the definition of messages in a generic way, facilitating the integration of new devices and suppliers.
              </p>
              <div className="center">
                <img src="/img/articles/msgs-templates.png" width={"50%"} height={"50%"} ></img>
                <img src="/img/articles/list-templates.png" width={"50%"} height={"50%"} ></img>
              </div>
                <p>
                This feature is available in the Open Source version of Oktopus Controller, confirming our commitment to innovation and enrichment of the ecosystem.
                So that the community can benefit from cutting-edge technologies accessible to everyone, for use, study and improvements.
                </p>
                <p>
                Combined with the discovery of automatic parameters of the equipment, it is possible to perform actions remotely, such as firmware updates, network parameter configuration, problem diagnosis, among others.
                </p>
                <div style={{justifyContent:"center", display:"flex", height:"50%"}} >
                <img src="/img/articles/discovery.png" class="maxImage"></img>
                </div>
                <p>
                Using data extracted from the equipment, Oktopus creates customized dashboards, performance reports, event alerts, management screens, among others, available in the Enterprise version, which allow the system operator to have a holistic view of the network and take proactive action.
                Therefore, reducing the number of technical calls and problem-solving time. Access our <a target="_blank" href="https://demo.oktopus.app.br">Demo <i className="fa fa-external-link"></i></a> to know more.
                </p>
                <p>
                    Discover how Oktopus Controller can help to boost your business, contact us via <a href="mailto:vendas@oktopus.app.br">email</a>
                    , or <a
                    href="https://oktopus.app.br/schedule-meeting"
                    rel="nofollow"
                    >
                    schedule a conversation
                    </a>.
                </p>
              <h5>Written by:</h5>
              <Leandro lang={language} />
              <br />
              <span>Date: 31/10/2024</span>
            </div>
          ) : (
            <div className="blog-text">
              <div className="about-text">
                <h2>Templates de Mensagens USP e CWMP</h2>
              </div>
                <p> 
                    Apesar da definição de padrões de mensagens e estruturas de dados que foram criados pela Broadband Forum.
                    Cada fabricante de equipamentos possui em sua grande maioria um conjunto de parâmetros exclusivos que são utilizados para configurar e monitorar o dispositivo ou então realiza uma implementação do USP/CWMP que não segue à risca o padrão, retornando informações e realizando ações importantes de maneira diferentes das previstas.
                </p>
                <p>
                    Tendo isso em vista, torna-se uma tarefa árdua mapear os parâmetros de cada fornecedor e ainda sim manter a agnosticidade da plataforma e do fluxo de trabalho.
                    Logo, a Oktopus desenvolveu um módulo de templates reutilizáveis que permite a definição de mensagens de forma genérica, facilitando a integração de novos dispositivos e fornecedores.
                </p>
                <div className="center">
                <img src="/img/articles/msgs-templates.png" width={"50%"} height={"50%"} ></img>
                <img src="/img/articles/list-templates.png" width={"50%"} height={"50%"} ></img>
                </div>
                <p>
                    Esta funcionalidade está disponível na versão Open Source do Oktopus Controller, firmando nosso compromisso com a inovação e enriquecimento do ecossistema. 
                    De forma que a comunidade possa se beneficiar com tecnologias de ponta acessíveis a todos, tanto para uso, estudo e melhorias.
                </p>
                <p>
                    Aliada a descoberta de parâmetros automáticos do equipamento, é possível realizar ações de forma remota, como por exemplo, atualização de firmware, configuração de parâmetros de rede, diagnóstico de problemas, entre outros.
                </p>
                <div style={{justifyContent:"center", display:"flex", height:"50%"}} >
                <img src="/img/articles/discovery.png" class="maxImage"></img>
                </div>
                <p>
                    A partir dos dados extraídos do equipamento, a Oktopus cria dashboards personalizados, relatórios de performance, alertas de eventos, telas de gerenciamento, entre outros disponíveis na versão Enterprise, que permitem ao operador do sistema ter uma visão holística da rede e tomar ações acertivas, de forma proativa.
                    Reduzindo assim o número de chamados técnicos e tempo de resolução de problemas. Acesse nossa <a target="_blank" href="https://demo.oktopus.app.br">Demo <i className="fa fa-external-link"></i></a> para saber mais.
                </p>
                <p>
                    Descubra como o Oktopus Controller pode ajudar a impulsionar o seu negócio, entre em contato 
                    conosco via <a href="mailto:vendas@oktopus.app.br">email</a>
                , ou <a
                  href="https://oktopus.app.br/schedule-meeting"
                  rel="nofollow"
                >
                  agende uma conversa
                </a>.
                </p>
               {/*  <p>
                    A seguir entenda mais a fundo sobre a troca de informações entre ACS/Controlador no TR-069/TR-369 e como 
                    como funcionam as camadas de abstração de mensagens e estruturas de dados presentes no Oktopus, que permitem uma experiência de gerenciamento simples, ao mesmo passo que eficiente e extensível.
                </p>
                <h3>Formato de Mensagens</h3>
                <p>
                    As mensagens definidas pela especificação USP são serializadas usando <a href="https://protobuf.dev/">Protocol Buffers</a>, que tráfegam em formato binário e são mais eficientes se comparadas ao JSON e XML. Para facilitar a leitura por humanos, 
                    a Oktopus entende as mensagens enviados pelo operador em JSON e posteriormente as transforma em Protocol Buffers para 
                    serem enviadas ao dispositivo. 
                </p>
                <img src="/img/articles/messages-parsing.png" class="maxImage" ></img>
                <p>
                    Mensagens CWMP são serializadas em XML, o que permite uma interface de comunicação mais amigável para humanos, similar ao que o JSON proporciona.
                </p>
                <img src="/img/articles/messages-parsing-CWMP.png" class="maxImage" ></img>
                <h3>Data Model</h3>
                <p>
                    O termo "data model" pode ser entendido como a definição da organização de dados da CPE. 
                    A estrutura de dados do USP segue o padrão TR-181, enquanto o CWMP segue o data model TR-098 ou TR-181 variando de acordo com as capacidades de cada roteador.
                </p>
                <p>
                    <ul>
                        <li><a href="https://cwmp-data-models.broadband-forum.org/" target="_blank">- Data Model CWMP</a></li>
                        <li><a href="https://usp-data-models.broadband-forum.org/" target="_blank">- Data Model USP</a></li>
                    </ul>
                </p>
                <p>
                    Templates de mensagens são carregados afim de mapear os parâmetros de cada modelo de equipamento. Apesar
                    disso, para garantir uma experiência mais rica em informações e ações, tornamos disponível na versão Enterprise
                    a visualização e edição de dados importantes, permitindo ao operador do sistema tomar ações de forma gráfica e intuítiva, tendo consigo uma visão holística da rede com fatores que influenciam a qualidade de serviço entregue e experiência do cliente.
                </p> */}
              <h5>Escrito por:</h5>
              <Leandro lang={language} />
               <br />
              <span>Data: 31/10/2024</span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
