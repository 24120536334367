import React from "react";

export default function Leandro ({lang}) {
  return (
    <div style={{textAlign:"center", marginTop:"15px"}}>
        <div style={{display:"flex"}}>
            <img src="/img/leandro.JPEG"  className="img-circle" style={{width:"70px",height:"70px"}} alt="Avatar"/>
            <div>
                <h5 style={{marginLeft:"5px",marginBottom:"0px"}}>Leandro Farias</h5>
                <span className="text-muted">{lang === "pt" ? "CEO na Oktopus" : "CEO at oktopus"}</span>
                <div style={{marginTop:"3px"}}>
                    <a href="https://www.linkedin.com/in/leandro-farias-machado" target="_blank" rel="noreferrer">
                        <i className="fa fa-linkedin"></i>
                    </a>
                    <a href="https://github.com/leandrofars" target="_blank" rel="noreferrer" style={{marginLeft:"5px"}}>
                        <i className="fa fa-github"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
}