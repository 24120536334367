import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, keywords, img}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
<meta name="keywords" content={keywords}></meta>
<meta property="og:type" name='type' content={"article"} />
<meta property="og:title" name='title' content={title} />
<meta property="og:description" name='description'  content={description} />
{img && <meta property="og:image" name='image'  content={img} />}
{/* <meta property="og:image" content={type} /> */}
</Helmet>
)
}