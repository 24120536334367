import React from "react";
import { useRef, useEffect } from "react";
import "./carrousel.css";

export const Carrousel = (props) => {
    const carouselRef = useRef(null);

    useEffect(() => {
      const handleItemClick = (event) => {
        const items = carouselRef.current.querySelectorAll(".item");
        items.forEach(item => {
          if (item !== event.currentTarget) {
            item.classList.remove("active");
          }
        });
        event.currentTarget.classList.toggle("active");
      };
  
      const items = carouselRef.current.querySelectorAll(".item");
      items.forEach(item => {
        item.addEventListener("mouseenter", handleItemClick);
      });
  
      // return () => {
      //   items.forEach(item => {
      //     item.removeEventListener("mouseover", handleItemClick);
      //   });
      // };
    }, []);
    
    // $(".custom-carousel").owlCarousel({
    //   autoWidth: true,
    //   loop: true
    // });
    // $(document).ready(function () {
    //   $(".custom-carousel .item").click(function () {
    //     $(".custom-carousel .item").not($(this)).removeClass("active");
    //     $(this).toggleClass("active");
    //   });
    // });
    
    return (
    <div className="game-section" id="game-section">
      <div className="container">
        <div className="enterprise-edition">
          <h2>Enterprise Edition</h2>
        </div>
      </div>
        <div className="owl-carousel custom-carousel owl-theme" ref={carouselRef} >
          {
            props.data.map((item, index) => {
              return (
                <div  className={index === 0 ? "item active" : "item"} key={index} style={{backgroundImage: `url(${item.img})`}}>
                  <div className="item-desc" >
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })
          }
        {/* <div className="item active" style={{backgroundImage: "url()"}}>
            <div className="item-desc">
            <h3>Geolocation</h3>
            <p></p>
            </div>
        </div> */}
        </div>
    </div>
    );
}