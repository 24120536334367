import React from "react";

export const Offer = (props) => {
    return (
        <div id="offer" className="section-pricing  text-center">
            <div className="container ">
                <div className="row " >
                    <div className="section-title">
                        <h2>{props.data.title}</h2>
                        <p>{props.data.paragraph}</p>
                        <p>{props.data.paragraph2} <a target="_blank" href="https://demo.oktopus.app.br">Demo <i className="fa fa-external-link"></i></a></p>
                        {/* <p><a href="https://demo.oktopus.app.br">Demo <i className="fa fa-external-link"></i></a></p> */}
                    </div>
                    {/* <div className="col-sm-4 col-md-6 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                        {props.data ? <h3 className="card-title">{props.data.title}</h3>:"loading"}
                            <div className="list-style">
                            <div>
                                <ul>
                                {props.data
                                ? props.data.why.map((d, i) => (
                                <li key={`${d}-${i}`}> {d}</li>
                                ))
                                : "loading"}
                                </ul>
                            </div>
                            </div>
                            {props.data && <a href="mailto:leandro@oktopus.app.br" className="btn btn-custom">{props.data.quote}</a>}
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 col-md-6 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">SaaS</h3>
                            <p className="card-text">Coming Soon...</p>
                             <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div> */}
                    {/* <div className="row display-flex justify-content-center">
				<div className="plans">
					<span className="plan monthly active">{props.data.month}</span>
					<span className="plan yearly">{props.data.year}</span>
					<span className="bg-active"></span>
				</div>
			</div> */}
                    <div className="row justify-content-center ">
                        <div className="col-md-4">
                            <div className="single-pricing free">
                                <div className="header text-center">
                                    <h3 className="title">{props.data.free.name}</h3>
                                    <p>{props.data.free.paragraph}</p>
                                </div>
                                <div className="content">
                                <p><b>{props.data.free.warning}</b></p>
                                    {/* <span className="monthly-price price">$0.00</span>
							<span className="yearly-price price">$0.00</span> */}
                                    <ul className="">
                                        {props.data.free.features.length > 0 && props.data.free.features.map((d, i) => (
                                            <li className="yes">{d}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="try-button">
                                    <a href="https://docs.oktopus.app.br" target="_blank" rel="noreferrer">{props.data.free.access} <i className="fa fa-external-link"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="single-pricing startup">
                                <div className="header text-center">
                                    <h3 className="title">{props.data.onpremises.name}</h3>
                                    <p>{props.data.onpremises.paragraph}</p>
                                </div>
                                <div className="content">
                                    <p><b>{props.data.onpremises.warning}</b></p>
                                    {/* <span className="monthly-price price">{props.data.onpremises.pricing}<span className="perDevice"> {props.data.onpremises.model}</span></span> */}
                                    {/* <span className="yearly-price price">$60.00</span> */}
                                    <ul className="">
                                    {/* <li><b>{props.data.onpremises.warning}</b></li> */}
                                        {props.data.onpremises.features.length > 0 && props.data.onpremises.features.map((d, i) => (
                                            <li className="yes">{d}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="try-button">
                                    <a target="_blank" href={`mailto:sales@oktopus.app.br?subject=${props.data.onpremises.emailSubject}`}>{props.data.onpremises.buy} </a>
                                </div>
                                <div className="try-button second-try-button">
                                    <a target="_blank" href="/schedule-meeting"> <i className="fa fa-calendar"></i> {props.data.onpremises.schedule}</a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
					<div className="single-pricing popular">
						<div className="header text-center">
							<h3 className="title">Popular</h3>
							<p>Lorem ipsum dolor sit amet</p>
						</div>
						<div className="content">
							<span className="monthly-price price">$25.00</span>
							<span className="yearly-price price">$80.00</span>
							<ul className="">
								<li className="yes"><i className="far fa-check-circle"></i>10GB Storage Space</li>
								<li className="yes"><i className="far fa-check-circle"></i>50GB Bandwidth</li>
								<li className="yes"><i className="far fa-check-circle"></i>10 Free Sub-Domains</li>
								<li className="yes"><i className="far fa-check-circle"></i>100 Email Accounts</li>
								<li className="yes"><i className="far fa-check-circle"></i>Control Panel & FTP</li>
								<li className="no"><i className="far fa-times-circle"></i>Unlimited Support</li>
							</ul>
						</div>
						<div className="try-button">
							<a href="#">Buy</a>
						</div>
					</div>
				</div> */}
                        {/* <div className="col-md-4">
                            <div className="single-pricing premium">
                                <div className="header text-center">
                                    <h3 className="title">{props.data.saas.name}</h3>
                                    <p>{props.data.saas.paragraph}</p>
                                </div>
                                <div className="content">
                                    <span className="monthly-price price">{props.data.saas.pricing}<span className="perDevice"> {props.data.saas.model}</span></span>
                                    <span className="yearly-price price">$250.00</span>
                                    <ul className="">
                                        {props.data.free.features.length > 0 && props.data.free.features.map((d, i) => (
                                            <li className="yes">{d}</li>
                                        ))}
                                        <li className="yes">-------------------------------------------------------</li>
                                        {props.data.onpremises.features.length > 0 && props.data.onpremises.features.map((d, i) => (
                                            <li className="yes">{d}</li>
                                        ))}
                                        <li className="yes"><i className=""></i>-------------------------------------------------------</li>
                                        {props.data.saas.features.length > 0 && props.data.saas.features.map((d, i) => (
                                            <li className="yes"><i className="fa fa-check-circle"></i>{d}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="try-button">
                                    <a href="#">Buy</a>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <div className="single-pricing premium">
                                <div className="header text-center">
                                    <h3 className="title">{props.data.consultancy.name}</h3>
                                    <p>{props.data.consultancy.paragraph}</p>
                                </div>
                                <div className="content">
                                <p><b>{props.data.consultancy.warning}</b></p>
                                    <ul className="">
                                        {props.data.consultancy.features.length > 0 && props.data.consultancy.features.map((d, i) => (
                                            <li className="yes">{d}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="try-button">
                                    <a target="_blank"  rel="noreferrer" href={`mailto:sales@oktopus.app.br?subject=${props.data.consultancy.emailSubject}`}>{props.data.consultancy.buy} </a>
                                </div>

                                <div className="try-button second-try-button">
                                    <a target="_blank" href="/schedule-meeting"  rel="noreferrer"> <i className="fa fa-calendar"></i> {props.data.consultancy.schedule}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}