import React from "react";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
// import {Header} from "./header"
// import { Partners } from "./partners";
// import { Team } from "./team";
// import { Offer } from "./offer";
import ScrollToAnchor from "./utils/ScrollToAnchor";
import Controller from "./controller";
// import { Testimonials } from "./testimonials";
// import { Team } from "./Team";

export default function Main ({landingPageData}) {
    return (
        <>
        <ScrollToAnchor />
        {/* <Header data={landingPageData.Header} /> */}
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        {/* <Team data={landingPageData.Team} /> */}
        {/* <Partners data={landingPageData.Partners}/> */}
        <Gallery data={landingPageData.Gallery} />
        {/* <About data={landingPageData.ControllerAbout} /> */}
        <Controller data={landingPageData} />
        {/* <Offer data={landingPageData.PricingPlans} /> */}
        </>
    )
}