import React from "react";
import { useLanguage } from "../../context/language";
import SEO from "../SEO";
import Leandro from "./leandro";

export default function OneMillionDevices(props) {
  const { language } = useLanguage();
  return (
    <section id="blog">
      <SEO
        title={"Oktopus | Blog"}
        description={"How to scale USP to 1 Million devices"}
        keywords={
          "USP,TR-369,scalability,1 million, million devices, millions of devices, obuspa"
        }
      />
      <div className="container">
        <div className="row">
          {language === "en" ? (
            <div className="blog-text">
              <div className="about-text">
                <h2>scaling USP to 1 Million devices</h2>
              </div>
              <p>
                Achieving scalability to support a million devices requires
                robust load and stress testing. For the Oktopus Controller, we
                utilize the{" "}
                <a href="https://github.com/BroadbandForum/obuspa">OBUSPA</a>{" "}
                project to simulate USP devices, conducting tests to understand
                performance limits and introducing necessary safeguards against
                bursts of device connections.
              </p>
              <p>
                From these tests, our team has developed several architectural
                enhancements which will be discussed later on this text. At this
                article you will find in-depth information on the USP
                Controller's configuration and the methodologies behind our
                stress tests.
              </p>
              <p>
                Two major cloud providers were used:{" "}
                <a href="https://cloud.google.com/">Google Cloud Platform</a>{" "}
                and <a href="https://aws.amazon.com">AWS</a>, with resources
                deployed across multiple regions. We utilized Terraform Language
                to spin up instances running OBUSPA containers. This setup
                allowed precise control over container deployment rates,
                offering valuable insights into system behavior and scalability.
              </p>
              <p>
                The Oktopus architecture comprises Golang-based microservices
                interconnected through the{" "}
                <a href="https://nats.io/">NATS messaging system</a>. Each
                Message Transfer Protocol (MTP) module includes a translator
                that converts USP messages from protocols like MQTT, STOMP, and
                WebSockets to NATS format. This modular approach facilitates the
                creation of scalable microservices that can handle device data
                independently of their connection type.
              </p>
              <p>
                Communication between the system's Core and Edge are abstracted
                and independent.{" "}
                <i>
                  Managing millions of connections without downtime is a key
                  challenge
                </i>
                . To address this issue Oktopus relies on multiple MTPs spreaded
                across regions near to the device: what we call the{" "}
                <b>Edge Layer</b>. Each device has a MTP redundant connection,
                so even if one server goes down for some reason, there’s still
                another connection path available.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/BasicConnections.gif"
                  style={{ maxWidth: "60%" }}
                ></img>
              </div>
              <p>
                It is still possible to maintain the connection with other
                controllers, creating a parallel connection with the device,
                without compromising performance and without being tied to a
                single ecosystem, as can be seen in the image below.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/ThirdPartyController.gif"
                  style={{ maxWidth: "50%" }}
                ></img>
              </div>
              <p>
                For those looking beyond, Oktopus offers the Enterprise plan,
                where the focus is on guaranteeing delivery, redundancy,
                security, and reliability. Our use of design patterns ensures
                multi-site and multi-tenancy support and configuration,
                immediately adapting to errors by rerouting to alternative
                geographical locations if a data center goes offline.
              </p>
              <p>
                In the next example you will find another scenario, with some
                possible failure points. Since here is only one Oktopus core,
                all traffic is concentrated in just one main instance.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/OneCore.gif"
                  style={{ maxWidth: "60%" }}
                ></img>
              </div>
              <p>
                For optimal reliability, deploying multiple site cores is
                crucial. A distributed architecture mitigates failure points and
                ensures continuous device communication, even during
                disruptions. The next illustration shows the benefits of this
                architecture:
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/ComplexConnections2.gif"
                  style={{ maxWidth: "60%" }}
                ></img>
              </div>
              <p>
                Devices can connect through multiple MTPs across different
                protocols, providing resilience. With these multiple
                connections, we have diverse possibilities. Example: If a port
                is accidentally blocked, alternative protocols are available. If
                the connection between Edge and Core fails, traffic is rerouted
                to the nearest operational cluster. These strategies are
                examples of how we optimize Oktopus enterprise-grade reliability
                solutions.
              </p>
              <h3>In the end, what are the system's resource requirements?</h3>
              <p>
                Following extensive testing and optimizations, our performance
                evaluation is segmented into Core and Edge resource usage:
              </p>
              <p>
                <b>Edge Layer</b>: Charged with maintaining device connections,
                each using roughly 40 kBytes of memory. For every 10 connections
                per second, CPU usage approximates 15% of 1 vCPU, so more
                connections per second more CPU usage. In case of network
                failures, packets are rerouted to other clusters or retained
                until reconnection.
              </p>
              <p>
                <b>Core Layer</b>: Implemented with Kubernetes for automated
                scalability based on demand, each microservice handles its
                responsibilities individually, allowing precise scaling per
                activity level, therefore, resource usage depends on the number
                of system operators, the quantity and types of devices, and the
                specific integrations that are present. For on-premises setups,
                we use K3S, and for cloud deployments, we use EKS, each offering
                distinct benefits.
              </p>
              <p>
                Contact us by email:{" "}
                <a href="mailto:sales@oktopus.app.br">sales@oktopus.app.br</a>,
                or schedule a conversation:{" "}
                <a
                  href="https://oktopus.app.br/schedule-meeting"
                  rel="nofollow"
                >
                  https://oktopus.app.br/schedule-meeting
                </a>
                , and find out how we can help to boost your business.
              </p>
              <h5>Written by:</h5>
              <Leandro lang={language} />
              <br />
              <span>Date: 16/10/2024</span>
            </div>
          ) : (
            <div className="blog-text">
              <div className="about-text">
                <h2>Dimensionando USP para 1 milhão de dispositivos</h2>
              </div>
              <p>
                Para garantir a qualidade e confiabilidade do sistema, fazer
                testes de carga e estresse é muito importante, com o projeto{" "}
                <a href="https://github.com/BroadbandForum/obuspa">OBUSPA</a>{" "}
                que simula um dispositivo USP conseguimos escalar a solução,
                testar situações adversas como exemplo uma rajada de
                dispositivos conectando ou mesmo entender os limites e proteções
                que devemos adicionar ao sistema.
              </p>
              <p>
                Para os testes, foram usados ​​dois provedores de nuvem (
                <a href="https://cloud.google.com/">Google</a> e{" "}
                <a href="https://aws.amazon.com">AWS</a>) em diferentes regiões.
                Com a linguagem Terraform criou-se múltiplas instâncias com
                contêineres OBUSPA. Como é possível controlar o número
                de contêineres por segundo, foi possível entender o
                comportamento do sistema e controlar variações.
              </p>
              <p>
                Como resultado, nosso time desenvolveu diversas soluções e esta
                página mostrará as arquiteturas possíveis, juntamente com uma visão detalhada do
                Controlador USP, aprofundando-se nos aspectos técnicos usados
                ​​nos testes de estresse.
              </p>
              <p>
                O software é construído com microsserviços Golang, que se
                comunicam entre si por meio do sistema de mensagens{" "}
                <a href="https://nats.io/">NATS</a>. Cada MTP tem um tradutor
                que converte mensagens USP de protocolos específicos (MQTT,
                STOMP e Websockets) para o NATS. Isso torna possível criar
                vários microsserviços e módulos para manipular dados do
                dispositivo sem precisar saber a qual MTP o dispositivo está
                conectado.
              </p>
              <p>
                A comunicação do sistema Core e Edge é abstraída e independente.
                O principal desafio é lidar com milhões de conexões em MTPs,
                garantindo a comunicação a todo tempo. Para resolver esse
                problema, Oktopus depende de vários MTPs espalhados por regiões
                próximas ao dispositivo; o que chamamos de Edge Layer. E cada
                dispositivo tem uma conexão MTP redundante, então, mesmo que um
                servidor fique inativo por algum motivo, ainda há outro caminho
                de conexão disponível.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/BasicConnections.gif"
                  style={{ maxWidth: "60%" }}
                ></img>
              </div>
              <p>
                Ainda é possível manter a conexão com outros controladores,
                criando uma conexão paralela com o dispositivo, sem comprometer
                o desempenho e sem ficar preso a um único ecossistema, como pode
                ser visto na imagem abaixo.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/ThirdPartyController.gif"
                  style={{ maxWidth: "50%" }}
                ></img>
              </div>
              <p>
                Para clientes corporativos, nosso foco é garantir comunicação,
                redundância, segurança e confiabilidade. Usamos muitos padrões
                de desenvolvimento de software para garantir que o Oktopus tenha
                disponibilidade multisite e multilocação, o que significa que o
                sistema é projetado desde o seu início para encontrar
                alternativas em situações de erro. Por exemplo: quando um data
                center está inativo, o Oktopus Controller encontrará a melhor
                alternativa geograficamente para manter o serviço ativo.
              </p>
              <p>
                No próximo exemplo, há apenas um Oktopus Core que mostra pontos
                de falha devido ao fato de que tudo está concentrado em apenas
                uma instância principal.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/OneCore.gif"
                  style={{ maxWidth: "60%" }}
                ></img>
              </div>
              <p>
                Se decidir por mais confiabilidade e garantia, muitos sites
                (Oktopus Core) são essenciais, a próxima imagem mostra os
                benefícios dessa arquitetura, muitos momentos de falha e ainda
                há comunicação com o dispositivo.
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  alt="USP img"
                  src="/img/articles/ComplexConnections2.gif"
                  style={{ maxWidth: "60%" }}
                ></img>
              </div>
              <p>
                É possível que um dispositivo se conecte a vários MTPs, mesmo
                que sejam protocolos diferentes. Com essas múltiplas conexões,
                temos várias possibilidades. Se uma porta for bloqueada
                acidentalmente, ainda há outro caminho/protocolo. Se a conexão
                intermediária cair, do Edge para o Oktopus Core, redirecionamos
                o tráfego para o cluster mais próximo. Esses são alguns exemplos
                das várias ações que tomamos para garantir a confiabilidade do
                sistema, arquitetura e soluções desenvolvidas exclusivamente em
                versões corporativas.
              </p>
              <h3>Conclusão: </h3>
              <p>
                Após diversas melhorias, testes e análises, o resultado do
                estudo de desempenho é separado em duas partes, recursos
                utilizados pela camada Core e pela camada Edge.
              </p>
              <p>
                <b>Edge</b>: Responsável por manter a conexão com cada
                dispositivo, como informação e referência cada conexão consome
                cerca de 40 kBytes de memória e a CPU consome 15% de 1vCPU para
                10 conexões por segundo, então mais conexões por segundo, mais
                CPU. Se ocorrer uma falha de rede, todos os pacotes serão
                retransmitidos para outro cluster ou retidos até que a conexão
                seja estabilizada.
              </p>
              <p>
                <b>Core</b>: Usamos o Kubernetes, assim tornamos o sistema auto
                escalável, o recurso é alocado de acordo com o uso. Cada
                micro-serviço é responsável por suas próprias ações, exemplo, se
                houver mais ações/modificações nos dispositivos, apenas o
                micro-serviço responsável pelo controle do dispositivo será
                escalado, assim como outros micro-serviços em outros momentos
                escalonam pela necessidade. Para On-Premises usamos K3S e para
                Cloud usamos EKS-AWS, cada um com suas vantagens e desvantagens.
              </p>
              <p>
                {" "}
                Entre em contato conosco via email:{" "}
                <a href="mailto:vendas@oktopus.app.br">vendas@oktopus.app.br</a>
                , ou agende uma conversa:{" "}
                <a
                  href="https://oktopus.app.br/schedule-meeting"
                  rel="nofollow"
                >
                  https://oktopus.app.br/schedule-meeting
                </a>
                , e descubra como podemos ajudar a impulsionar o seu negócio.
              </p>
              <h5>Escrito por:</h5>
              <Leandro lang={language} />
               <br />
              <span>Data: 16/10/2024</span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
