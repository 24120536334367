import React from "react";

export default function Younes ({lang}) {
  return (
    <div style={{marginTop:"-10px"}}>
        <div style={{display:"flex", alignItems:"center"}}>
            <img src="/img/younes.png"  className="img-circle" style={{height:"100px"}} alt="Avatar"/>
            <div style={{marginLeft:"25px",}}>
                <h5>Younes Medhioub</h5>
                <span className="text-muted">{lang === "pt" ?
                "Younes é um engenheiro de computação industrial especializado em sistemas inteligentes e interconectados. Com uma forte paixão por IA, redes e desenvolvimento de software embarcado, ele aprimorou suas habilidades em projetar soluções Linux embarcadas e implantar sistemas inteligentes personalizados. Sua experiência abrange trabalho colaborativo e liderança de equipe, onde ele prospera em ambientes dinâmicos. Buscando ativamente novos desafios, ele está ansioso para aplicar sua experiência e contribuir para projetos impactantes e com visão de futuro." : 
                "Younes is an Industrial Computing engineer specializing in Intelligent and Interconnected Systems. With a strong passion for AI, Networking, and embedded software development, he has honed skills in designing embedded Linux solutions and deploying custom intelligent systems. His experience spans collaborative work and team leadership, where he thrives in dynamic environments. Actively seeking new challenges, he is eager to apply his expertise and contribute to impactful, forward-thinking projects."}</span>
                <div style={{marginTop:"3px"}}>
                    <a href="https://www.linkedin.com/in/younes-medhioub/" target="_blank" rel="noreferrer">
                        <i className="fa fa-linkedin"></i>
                    </a>
                    <a href="https://github.com/younes-medhioub" target="_blank" rel="noreferrer" style={{marginLeft:"5px"}}>
                        <i className="fa fa-github"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
}