import React from "react";
import { Services } from "./services";
//import { Testimonials } from "./testimonials";
import { Offer } from "./offer";
import { Carrousel } from "./carrousel";

export default function Controller(props){
  return (
    <div style={{padding:"100px 0px 50px 0px"}}>
      <div id="controller">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
  {props.data.Controller.Img && <img src={props.data.Controller.Img} className="img-responsive" id="img-about" alt=""/>}
            </div>
            <div className={props.data.Controller.Img ? "col-xs-12 col-md-6":"col-xs-12 col-md-12"}>
              <div className="about-text">
                <h2>{props.data.Controller.About}</h2>
                <p>{props.data.Controller ? props.data.Controller.paragraph : "loading..."}</p>
                {props.data.Controller.Whys && <><h3>{props.data.Controller.Whys.Why_Choose}</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data.Controller.Whys
                        ? props.data.Controller.Whys.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Controller.Whys.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div></>}
                <div style={{
                  paddingTop:'140px'
                }}></div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="text-center">
      <div className="container">
      {props.data.Controller.Services.title && 
        <div className="section-title">
          <h2>{props.data.Controller.Services.title}</h2>
          <p>
            {props.data.Controller.Services.slogan}
          </p>
        </div>
      }
        <div className="row">
          {props.data.Controller.Services.data
            ? props.data.Controller.Services.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="row">
          {props.data.Controller.Services.data2
            && props.data.Controller.Services.data2.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            }
        </div>
      </div>
    </div>
      {/* <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
  {props.data.Controller.Img && <img src={props.data.Controller.Img} className="img-responsive" id="img-about" alt=""/>}
            </div>
            <div className={props.data.Controller.Img ? "col-xs-12 col-md-6":"col-xs-12 col-md-12"}>
              <div className="about-text">
                {props?.data.Whys2 && <><h3>{props.data.Controller.Whys2.Why_Choose}</h3>
                <div className="list-style">
                {props?.data.paragraph2 && <p>{props.data.Controller.paragraph2}</p>}
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data.Controller
                        ? props.data.Controller.Whys2.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data.Controller
                        ? props.data.Controller.Whys2.Why2?.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
                </>}  
              </div>
                        </div>
          </div>
        </div>
      </div> */}
            { props.data.Controller.Icons && <div className="col-md-12">
              <div className="row">
                <div className="social" style={{paddingTop:'50px', paddingBottom:'50px'}}>
                  <ul>
                    {props.data.Controller
                      ? props.data.Controller.Icons?.map((d, i) => (
                          <li key={`${d.icon}-${i}`}>
                            <a href={d.link} target="_blank" rel="noreferrer" title={d.msg}>
                              <i className={d.icon}></i>
                            </a>
                            <div style={{display:"block"}}>
                            {d.msg}
                            </div>
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>}
      <Carrousel data={props.data.Carousel}></Carrousel>
      <Offer data={props.data.PricingPlans}></Offer>

    </div>
  );
};
