import React from "react";
import { useEffect, useRef, useState } from "react";

export const Navigation = ({ data }) => {

  const navigationRef = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const sections = document.querySelectorAll("section");
  const navLi = document.querySelectorAll("nav .container ul li");

  const scrollHandler = () => {
    var current = "";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop -300;
      if (scrollPosition >= sectionTop) {
        current = section.getAttribute("id");
      }
    });

    // console.log("current section: ",current)
    // console.log("navLi:", navLi)

    navLi.forEach((li) => {
      li.classList.remove("active");
      if (li.classList.contains(current)) {
        li.classList.add("active");
      }
    });
  };

  window.onscroll = scrollHandler();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    const handleItemClick = (event) => {
      const items = navigationRef.current.querySelectorAll(".navigation-option");
      items.forEach(item => {
        if (item !== event.currentTarget) {
          item.classList.remove("active");
        }
      });
      event.currentTarget.classList.add("active");
    };

    const items = navigationRef.current.querySelectorAll(".navigation-option");
    items.forEach(item => {
      item.addEventListener("click", handleItemClick);
    });

    return () => {
      items.forEach(item => {
        item.removeEventListener("click", handleItemClick);
      });
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className='navbar-header'>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="/img/oktopus-removebg.png" width={'200px'} alt="oktopus logo with no bg" className="img-responsive"></img>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right" ref={navigationRef}>
            {/* <li className={"navigation-option"}>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
            <li className={"navigation-option about"}>
              <a href="/#about" className="page-scroll">
                {data.About}
              </a>
            </li>
            <li className={"navigation-option services"}>
              <a href="/#services" className="page-scroll">
                {data.Services}
              </a>
            </li>
            {/* <li className={"navigation-option"}>
              <a href="/#partners" className="page-scroll">
                {data.Partners}
              </a>
            </li> */}
            <li className={"navigation-option portfolio"}>
              <a href="/#portfolio" className="page-scroll">
                {data.Solutions}
              </a>
            </li>
            <li className={"navigation-option blog"}>
              <a href="/blog" className="page-scroll">
                Blog
              </a>
            </li>
            {/* <li className={"navigation-option"}>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            {/* <li className={"navigation-option"}>
              <a href="#team" className="page-scroll">
                {data.Team}
              </a>
            </li> */}
            <li className={"navigation-option contact"}>
              <a href="/#contact" className="page-scroll">
                {data.Contact}
              </a>
            </li>
            <li>
              <div style={{
                justifyContent: 'center',
                display: 'flex',
                paddingTop: '20px',
                paddingLeft: '15px'
              }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.setItem("lang", "pt")
                    window.location.reload()
                  }}>
                  <img src="/img/pt_BR.png" alt="pt_br flag" />
                </span>
                <div style={{ width: '20px' }}></div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.setItem("lang", "en")
                    window.location.reload()
                  }}>
                  <img src="/img/en_US.png" alt="en_us flag" />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
