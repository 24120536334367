// import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <section id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data.Gallery}</h2>
          <p>
            {props.data.paragraph}
          </p>
        </div>
        <div className="smartCity">
          <img src="img/city.png" className="img-responsive" width={"400px"} alt="smart city" ></img>
        </div>
        {/* {props.data.Galleries
          ? props.data.Galleries.map((d, i) => (
            <div className="media">
              <div
                key={`${d.title}-${i}`}
                className="col-sm-6 col-md-4 col-lg-2 "
              >
                <Image
                  title={d.title}
                  smallImage={d.smallImage}
                  link={d.link}
                  slogan={d.slogan}
                />
              </div>
              <div className="media-body">
                <h3 className="mt-0">{d.title}</h3>
                <p>Gestão centralizada de suas CPEs e dispositivos IoT, com todas as vantagens que o protocolo TR-369 oferece.</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          ))
          : "Loading..."} */}
      </div>
    </section>
  );
};
