import React from "react";
import { useLanguage } from "../../context/language";
import SEO from "../SEO";
import Leandro from "./leandro";

export default function WhatisOktopus(props) {
    const { language } = useLanguage();
    return (
        <section id="blog">
            <SEO title={"Oktopus | Blog"} description={"What is the Oktopus Controller? Who is it for? What it does and how it can help to increase your QoS and QoE levels"} keywords={"Oktopus,USP,CWMP,TR-069,TR-369"} img="/img/articles/oktopus-initial.png"/>
            <div className="container">
                {language === "en" ?
                    <div className="blog-text">
                        <div className="about-text">
                            <h2>What is the Oktopus Controller?</h2>
                        </div>
                        <p>
                            With the Oktopus Controller you can monitor, configure, update and perform many other actions remotely on your clients' devices, without the need for manual intervention. You control all your CPEs from different brands through a single centralized interface with your logo and theme, intuitive and easy to use, allowing for quick, precise and efficient diagnosis of problems.
                        </p>
                        <h3>Main Features: </h3>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <img src="/img/articles/map.png" width={"100%"} height={"300px"} alt="map" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Geolocation:</h4>
                                <p>
                                    With the data extracted from your device we obtain the geolocation of the same through antenna triangulation, allowing the visualization of all devices on a map.
                                </p>
                                <p>
                                    This way, you are able to identify problems by regions, check regions with higher and lower concentration of devices, identifying possible interferences and places for commercial expansion.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6">
                                <h4>3rd part intergration:</h4>
                                <p>
                                    A REST API is available for integration with other systems, allowing task automation and integration with third-party systems.
                                </p>
                                <p>
                                    The Application Programming Interface(API) allows for communication between different applications, ensuring a seamlessly workflow that embraces all different areas of your operation.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/api.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/diagnostic.png" width={"100%"} height={"300px"} alt="diagnostic-tools" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Diagnostic Tools:</h4>
                                <p>
                                    Precise and efficient diagnosis of network problems. Use troubleshooting tools to debug and identify connectivity problems.
                                </p>
                                <p>
                                   Have at your disposal: Speed Test, Traceroute and Ping, for the successful resolution of connectivity problems.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6">
                                <h4>Firmware Update and Reboot</h4>
                                <p>
                                    Don't ask your customer to restart the equipment anymore, risking physical problems. Reboot remotely, without the need for manual intervention.
                                </p>
                                <p>
                                    Update the firmware of your devices remotely, without headaches, always keeping them up to date. Avoid possible security flaws and always ensure you have the latest features available.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-6" >
                                 <h4>Role Based Access Control:</h4>
                                <p>
                                    Configure users with different levels of access to the platform. Define who can view, edit and perform actions on your devices.
                                </p>
                                <p>
                                    Maintain compliance with your company's organization, having granular user roles to avoid unwanted accesses on your equipment.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/white-label.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Custom User Interface:</h4>
                                <p>
                                    Fully customizable user interface, with your company's logo and theme.
                                </p>
                                <p>
                                    Increase your customers' confidence in your solution and the credibility of your brand, with the same functionalities as the standard web interface version.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6">
                                <h4>WiFi:</h4>
                                <p>
                                    Perform basic settings such as password change, network name (SSID) and channel. View connected devices and the signal level each one is receiving.
                                </p>
                                <p>
                                    In addition to basic settings, it is also possible to perform advanced actions, such as scanning nearby networks with the noise level of each one and in which channel they are. Thus, it is possible to configure the WiFi on a less polluted channel, considerably increasing the quality of the wireless network.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/sitesurvey.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/insights.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Multi Vendor:</h4>
                                <p>
                                    Manage devices from different vendors. You can manage all your devices from different brands through a single centralized interface with your logo and theme, intuitive and easy to use, with powerful insights and network analysis.
                                </p>
                                <p>
                                    Don't get stuck with an expensive and inflexible solution from a single Vendor. Choose Oktopus Controller, and if you don't have a good experience with us, easily switch to other compatible market solutions.
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                        <h3>Enterprise and Community Editions </h3>
                        <p>
                            The Community edition is aimed at small internet providers and enthusiasts who wish to use the software at no cost or create alternative solutions based on our Controller's core. Oktopus is Open Source, which means you can study, modify, and distribute the software following the terms of the Apache2 license.
                        </p>
                        <p>
                            The Enterprise edition is aimed at internet providers and IT integrators looking for a complete end-to-end solution, robust, with specialized technical support and a high level of SLA. In this model, you acquire a commercial license to use the software and have access to all the features and updates of the Controller. As well as support, training, and integration with the systems you already use.
                        </p>
                        {/* <p>
                            Nosso Controller segue a especificação dos protocolos <a href="https://www.broadband-forum.org/pdfs/tr-069-1-6-1.pdf" >TR-069 (CWMP)</a> e <a hre="https://usp.technology/specification/index.htm">TR-369 (USP)</a>. Sendo o único atualmente no mercado que é Open Source e possui suporte a ambos os padrões. Fique atento para mais conteúdos sobre a Oktopus, os protocolos TR-069 e TR-369 e como eles estão ajudando empresas do ramo de telecomunicações e IoT a crescerem e ofertarem mais e melhores serviços aos seus clientes.
                        </p> */}
                        <p>
                            Got interested and want to know more? Reach us through email: <a href="mailto:contact@oktopus.app.br">contact@oktopus.app.br</a> or <a href="/schedule-meeting" target="_blank">schedule a meeting</a>.
                        </p>
                        <h5>Written by:</h5>
                        <Leandro lang={language} />
                        <br />
                        <span>Date: 18/07/2024</span>
                    </div>
                    :
                    <div className="blog-text">
                        <div className="about-text">
                            <h2>O que é o Controlador Oktopus</h2>
                        </div>
                        {/* <p>
                        <ul>
                            <li>Seu provedor de internet sofre com <b>alto número de chamadas de suporte?</b></li>
                            <li>Você possui uma parque de <b>dispositivos com direntes marcas?</b></li>
                            <li>Tem <b>dificuldade em achar mão de obra qualificada</b> para gerência do seu parque e prestação de suporte?</li>
                            <li>Sente-se limitado e gostaria de <b>ofertar mais serviços?</b></li>
                        </ul>
                    </p> */}
                        <p>
                            Com o Controlador da Oktopus você pode monitorar, configurar, atualizar e realizar muitas outras ações remotamente nos dispositivos de seus clientes, sem precisar de intervenção manual. Você controla todo o seu parque de diferentes marcas através de uma única interface centralizada com sua logo e tema, intuitiva e fácil de usar, permitindo o diagnóstico rápido, preciso e eficiente de problemas.
                        </p>
                        <h3>Principais Funcionalidades: </h3>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <img src="/img/articles/map.png" width={"100%"} height={"300px"} alt="map" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Geolocalização:</h4>
                                <p>
                                    A partir dos dados extraídos de seu dispositivo obtemos a geolocalização do mesmo por meio de triangulação de antenas, permitindo a visualização de todos os dispositivos em um mapa.
                                </p>
                                <p>
                                    Desta maneira, você é capaz de identificar problemas por regiões, verificar locais com maior e menor concentração de dispositivos, identificando possíveis interferências e locais de expansão comercial.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6">
                                <h4>Integração com Terceiros:</h4>
                                <p>
                                    Uma API REST é disponibilizada para integração com outros sistemas, permitindo a automação de tarefas e a integração com sistemas de terceiros.
                                </p>
                                <p>
                                    API é um acrônimo para Application Programming Interface, ou seja, é uma interface de programação de aplicações que permite a comunicação entre diferentes aplicações.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/api.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/diagnostic.png" width={"100%"} height={"300px"} alt="diagnostic-tools" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Ferramentas de Diagnóstico:</h4>
                                <p>
                                    Diagnóstico preciso e eficiente de problemas de rede. Utilize ferramentas de troubleshooting para depurar e identificar problemas de conectividade.
                                </p>
                                <p>
                                    Tenha a sua disposição: Speed Test, Traceroute e Ping, para a resolução bem sucedida de problemas de conectividade.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6">
                                <h4>Atualização de Firmware e Reboot:</h4>
                                <p>
                                    Não peça mais para o seu cliente reiniciar o equipamento, correndo o risco de ocasionar problemas físicos. Realize o reboot de forma remota, sem a necessidade de intervenção manual.
                                </p>
                                <p>
                                    Atualize o firmware de seus dispositivos remotamente, sem dor de cabeça, mantendo-os sempre na última versão. Evitando assim possíveis falhas de segurança e garantindo sempre ter as últimas funcionalidades disponíveis.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-6" >
                                 <h4><i>Role Based Access Control:</i></h4>
                                <p>
                                    Configure usuários com diferentes níveis de acesso a plataforma. Defina quem pode visualizar, editar e realizar ações em seus dispositivos.
                                </p>
                                <p>
                                    Mantenha a conformidade com a organização de sua empresa, tendo funções de usuário granulares para evitar ações não desejadas nos seus equipamentos.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/white-label.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Interface Customizada:</h4>
                                <p>
                                    Interface de usuário totalmente customizável, com a logo e tema da sua empresa.
                                </p>
                                <p>
                                    Aumente a confiança de seus clientes na sua solução e a credibilidade de sua marca, com as mesmas funcionalidades da versão com interface web padrão.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                            <div className="col-xs-12 col-md-6">
                                <h4>WiFi:</h4>
                                <p>
                                    Realize configurações básicas como troca de senha, nome da rede (SSID) e canal. Visualize dispositivos conectados e o nível de sinal que cada um está recebendo.
                                </p>
                                <p>
                                    Além das configurações básicas, é possível realizar também ações avançadas, como o escaneamento de redes próximas com o nível de ruído das mesmas e em que canal se encontram. Sendo assim possível configurar o WiFi em um canal menos poluído, aumentando consideravelmente a qualidade da rede sem fio.
                                </p>
                            </div>
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/articles/sitesurvey.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-6" style={{ display: "flex", justifyContent: "center" }}>
                                <img src="/img/insights.png" width={"100%"} height={"300px"} alt="sitesurvey" />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h4>Multi Vendor:</h4>
                                <p>
                                Gerencie dispositivos de diferentes fornecedores. Você pode gerir todo o seu parque de diferentes marcas através de uma única interface centralizada com seu logotipo e tema, intuitiva e fácil de usar, com insights e análises poderosas da sua rede.
                                </p>
                                <p>
                                Não fique preso a uma solução cara e inflexível de um único Vendor. Opte pelo Controlador da Oktopus, e se não tiver uma boa experiência conosco, mude facilmente para outras soluções do mercado compatíveis.
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12" style={{ marginTop: "30px" }} />
                        <h3>Versões Enterprise e Community </h3>
                        <p>
                            A versão Community é voltada para pequenos provedores de internet e entusiastas que desejam utilizar o software sem custos ou criar soluções alternativas baseadas no core de nosso Controlador. O Oktopus é Open Source, o que significa que você pode estudar, modificar, e distribuir o software seguindo os termos da licença Apache2.
                        </p>
                        <p>
                            Já a versão Enterprise é voltada para provedores de internet e integradores de TI que buscam uma solução completa fim a fim, robusta, com suporte técnico especializado e alto nível de SLA. Neste modelo, você adquire uma licença de uso comercial do software e tem acesso a todas as funcionalidades e atualizações do Controlador. Assim como também o suporte, treinamento e integração com os sistemas que você já utiliza.
                        </p>
                        {/* <p>
                            Nosso Controller segue a especificação dos protocolos <a href="https://www.broadband-forum.org/pdfs/tr-069-1-6-1.pdf" >TR-069 (CWMP)</a> e <a hre="https://usp.technology/specification/index.htm">TR-369 (USP)</a>. Sendo o único atualmente no mercado que é Open Source e possui suporte a ambos os padrões. Fique atento para mais conteúdos sobre a Oktopus, os protocolos TR-069 e TR-369 e como eles estão ajudando empresas do ramo de telecomunicações e IoT a crescerem e ofertarem mais e melhores serviços aos seus clientes.
                        </p> */}
                        <p>
                            Se interessou e quer saber mais? Entre em contato conosco através do email: <a href="mailto:contato@oktopus.app.br">contato@oktopus.app.br</a> ou <a href="/schedule-meeting" target="_blank">agende uma conversa</a>.
                        </p>
                        <h5>Escrito por:</h5>
                        <Leandro lang={language} />
                        <br />
                        <span>Data: 18/07/2024</span>
                    </div>
                }
            </div>
        </section>
    )
};
