import React from "react";
import { useLanguage } from "../../context/language";
import SEO from "../SEO";
import Leandro from "./leandro";
import Younes from "./younes";

export default function OktopusAtAzure(props) {
    const { language } = useLanguage();
    return (
        <section id="blog">
            <SEO title={"Oktopus | Blog"} description={"Azure Cloud USP Controller Deployment"} keywords={"Oktopus,USP,TR-369,Cloud,Azure"} img="/img/articles/oktopus-initial.png" />
            <div className="container">
                {language === "en" ?
                    <div className="blog-text">
                        <div className="about-text">
                            <h2>USP Controller Cloud Deployment</h2>
                        </div>
                        <div>

                            <p>
                                Nowadays businesses need software that can adapt quickly and work smoothly across different environments. Oktopus is a custom solution, like many powerful tools, deploying it can be challenging. In this blog, we’ll walk through how to deploy Oktopus using Docker-Compose.
                                If you're looking for a straightforward way to deploy Oktopus without headaches, this guide is for you.
                            </p>

                            <p>
                                <h4>Note:</h4>
                                In this blog post, we assume that you have a Microsoft account with a valid Azure subscription. If not, feel free to sign up for one at the
                                <a href="https://azure.microsoft.com/en-us/pricing/purchase-options/azure-account?icid=azurefreeaccount"> Azure portal </a>
                                where you can start with free credits to explore and test out Azure services.
                            </p>

                            <h3>
                                Part 1: General architecture deployment

                            </h3>
                            <p>
                                The easiest way to install Oktopus is in a standalone mode with every component running in the same machine. However, in a production environment, it is recommended to deploy the MTPs (MQTT Broker, Websockets and STOMP servers) and ACS in different servers apart from the core Oktopus microservices. This way, you can scale your solution and have a more robust and secure environment.
                            </p>
                            <p>
                                Oktopus Controller has support to <b>TR-069 protocol</b> with <b>ACS</b> and <b>all USP MTPs</b> as well, although in this blog we will focus on USP standard through MQTT.
                            </p>
                            <p>
                                Our approach consists of deploying the following Oktopus components: USP Controller, MQTT broker and USP Agent (CPE).
                                All parts will be detailed in the next paragraphs.
                            </p>
                            <div className="center">
                                <img src="/img/articles/younes-general-architecture-azure.png" className="maxImage" />
                            </div>

                            <h3>Part 2: Infrastructure provisioning</h3>
                            <h4>1. Creating VMs </h4>
                            <p>To get started, we first create two Linux virtual machines (VMs) using the Azure portal. Ensure that you have full access to these machines, including SSH terminal and root privileges. If you encounter any difficulties during the setup, feel free to check out this <a href="https://www.youtube.com/watch?v=KqLWQgcyJiA>"> helpful tutorial</a> for step-by-step guidance.</p>
                            <p>
                                For the purposes of this blog, we will be running our tests using the following Azure VM plans, which we recommend for optimal performance:
                                <br />
                                <br />
                                <ul>
                                    <li>- MQTT Server (VM1): B2s plan</li>
                                    <li>- Oktopus (VM2): B1s plan</li>
                                </ul>
                            </p>

                            <h4>2. Installing Docker</h4>
                            <p>Install Docker and Docker-Compose on both VMs. The installation process is well-documented in the <a href="https://docs.docker.com/engine/install/ubuntu/">official Docker documentation</a>.</p>

                            <h4>3. Defining Network rules</h4>
                            <p>As you know, MQTT uses the port 1883 but it’s not allowed on default. So we need to define new port rules in both VMs.
                            </p>
                            <p>Choose <i>VM1</i>-&gt; <i>VM Networking</i> -&gt; <i>Inbound rules</i> -&gt; <i>Add inbound port rule</i>.</p>

                            <div className="justifyCenter">
                                <table style={{ borderCollapse: "collapse", border: "1px solid black" }}>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Source</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Any</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Source port range</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>*</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Destination</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Any</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Service</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Custom</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}><b>Destination port ranges</b></td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}><b>1883</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Protocol</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>TCP</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Action</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Allow</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Name</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>"mqtt non TLS"</td>
                                    </tr>
                                </table>
                            </div>

                            <p>We need also to create the same port rule using the same characteristics for outbound connections.
                            </p>
                            <p>
                                Choose <i>VM1</i>-&gt; <i>Networking</i> -&gt; <i>Outbound rules</i> -&gt; <i>Add outbound port rule</i>
                            </p>

                            <div className="justifyCenter">
                                <table style={{ borderCollapse: "collapse", border: "1px solid black" }}>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Source</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Any</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Source port range</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>*</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Destination</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Any</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Service</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Custom</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}><b>Destination port ranges</b></td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}><b>1883</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Protocol</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>TCP</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Action</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Allow</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>Name</td>
                                        <td style={{ border: "1px solid black", padding: "5px" }}>"mqtt non TLS"</td>
                                    </tr>
                                </table>
                            </div>
                            <p>In order to make sure that we successfully completed the right steps . Try SSH connection for both VMs and try this command at VM1:</p>
                            <pre>
                                <code>
                                    younes@BrokerMachine:~$ netstat -tulen
                                </code>
                            </pre>
                            <p>The output should be similar to this:</p>

                            <div className="center">
                                <img src="/img/articles/younes-output-mqtt.png" className="maxImage" />
                            </div>
                            <br></br>

                            <h4>4. Preparing Agent environment</h4>
                            <p>As can be seen in the arcthitecture section, we will be deploying OBUSPA across three different environments. However,  we will focus specifically on the WSL (Windows Subsystem for Linux) environment. Still though, you can follow the same steps provided on this guide to install USP agent on any other Linux or macOS distribution.</p>
                            <p>Installing WSL is straightforward. Simply follow the instructions provided in the <a href="https://learn.microsoft.com/en-us/windows/wsl/install">official Microsoft documentation</a> to get WSL up and running on your system.</p>

                            <h3>Part 3: Deployment of different components</h3>
                            <h4>1. MQTT Broker</h4>
                            <p>To use Oktopus, we need to deploy a custom-developed MQTT broker. This broker is based on the open-source Mochi MQTT broker but has been enhanced to automate specific actions through predefined hooks (e.g., sending topics that include the Agent EndpointID, custom MQTT packets headers, among other tasks). </p>

                            <p>To get started, simply pull the Docker image and follow these steps to start the broker:</p>

                            <pre>
                                younes@BrokerMachine:~$ sudo docker pull oktopusp/mqtt
                            </pre>
                            <pre>
                                younes@BrokerMachine:~$ sudo docker run -d --name mqtt_container -p 1883:1883 oktopusp/mqtt
                            </pre>

                            <p>The output should be similar to this</p>
                            <div className="center">
                                <img src="/img/articles/mqtt-container.png" className="maxImage" />
                            </div>
                            <br></br>
                            <h4>2. USP Controller</h4>
                            <p>The next task is to install Oktopus Controller on VM2. To accomplish this, follow the steps below:</p>

                            <pre>
                                <code>
                                    root@younesHost:/home/younes# git clone <a href="https://github.com/OktopUSP/oktopus.git">https://github.com/OktopUSP/oktopus.git</a>
                                </code>
                            </pre>

                            <pre>
                                <code>
                                    root@younesHost:/home/younes# cd oktopus/deploy/compose/
                                </code>
                            </pre>
                            <p>In order to make Oktopus to know the MQTT Server that we just installed in VM1, we need to configure it by editing the value of the variable "MQTT_URL" defined in  "deploy/compose/.env.mqtt-adapter". It’s value will be the IP address of the MQTT Server (VM1).</p>

                            <pre>
                                <code>
                                    root@younesHost:/home/younes/oktopus/deploy/compose# nano .env.mqtt.adapter
                                </code>
                            </pre>
                            <pre>
                                <code>
                                    MQTT_URL=tcp://&lt;vm1_ip_address&gt;:1883
                                </code>
                            </pre>

                            <p>The final step is to start it </p>

                            <pre>
                                <code>
                                    root@younesHost:/home/younes/oktopus/deploy/compose# COMPOSE_PROFILES=nats,controller,mqtt,adapter,frontend docker compose up -d
                                </code>
                            </pre>

                            <p>Make sure that we successfully completed the right steps. Try this command:</p>

                            <pre>
                                <code>
                                    root@younesHost:/home/younes# docker ps -a
                                </code>
                            </pre>

                            <p>You should see all Oktopus containers listed with status "Up".</p>
                            <h4>3. OBUSPA</h4>
                            <p>As mentioned earlier, we'll be installing OBUSPA on WSL. The complete steps are thoroughly detailed in the official Broadband Forum GitHub repository, at <a href="https://github.com/BroadbandForum/obuspa/blob/master/QUICK_START_GUIDE.md">QUICK START GUIDE section</a>.</p>

                            <p>
                                <h4>Note:</h4>
                                Here we install OBUSPA at the Operating System level, but it's also possible to run OBUSPA in a containerized environment.
                                The USP agent is encapsulated in a easy to use docker container. You can change the factory reset file the same way that we're going to do below and then run the Agent as explained at <a href="https://docs.oktopus.app.br/getting-started/installation/agent-simulator">Oktopus Agent Simulator documentation</a>.
                            </p>


                            <p>The objective is to ensure the agent recognizes the controller over the MQTT connection. To achieve this, we need to modify the agent's data model. Specifically, we’ll be editing OBUSPA's factory reset file. There is a MQTT configuration template provided on GitHub, which you can access and download from <a href="https://github.com/OktopUSP/oktopus/blob/main/agent/oktopus-mqtt-obuspa.txt">here</a>.</p>

                            <p>Now edit the file adding the public IP address of the MQTT Server:
                            </p>
                            <pre>
                                <code>
                                    Device.MQTT.Cient.1.BrokerAddress "<span>&lt;public_ip_address_of_mqtt_server_vm1&gt;</span>"
                                </code>
                            </pre>

                            <p>Please be cautious when making changes, as editing any other values may cause issues. Handle the file with care to avoid unexpected problems.</p>

                            <h3>Part 4 : Results and Validation</h3>
                            <p>Congratulations on making it this far! now it's time to see the results of all your hard work.
                                After acceding the Oktopus Web interface (it uses the same public IP address of VM2), create a user and login.
                            </p>
                            <p>
                                Start the agent (In WSL environment) using the config file that we just edited. You can simply use this command:
                            </p>
                            <pre>
                                <code>
                                    $ obuspa -p -v 4 -r oktopus-mqtt-obuspa.txt -i eth0
                                </code>
                            </pre>

                            <p>And here we go. The agent is successfully connected to Oktopus:</p>
                            <div className="center">
                                <img src="/img/articles/mqtt-device-online.png" className="maxImage" />
                            </div>

                            <p>After a long journey, you've now completed all the essential steps. Well done on your perseverance and hard work, it’s a significant achievement to reach this point!
                                With everything in place, the Oktopus Controller is now ready for action, and you're equipped to further enhance and optimize its functionality as needed.
                            </p>
                            <p>
                                For production workloads there are many details and aspects to consider, but the three main subjects not covered in this blog are:
                                <ul>
                                    <li>
                                        --&gt; MQTT TLS encryption.
                                    </li>
                                    <li>
                                        --&gt; TLS for Oktopus Web interface.
                                    </li>
                                    <li>
                                        --&gt; USP agent authentication to MQTT.
                                    </li>
                                </ul>
                                Hopefully we'll get those covered in a future blog post.
                            </p>
                            <p>
                                Keep in alert for more content about Oktopus, USP protocol and how it is helping companies in the telecommunications sector to grow and offer more and better services to their customers.
                            </p>
                        </div>
                        <h5>Written by:</h5>
                        <Younes lang={language} />
                        <br />
                        <span>Date: 15/11/2024</span>
                    </div>
                    :
                    <div className="blog-text">
                        <div className="about-text">
                            <h2>Instalação de Controlador USP na nuvem</h2>
                        </div>
                        <p>
                            No mundo de hoje, as empresas precisam de software que possa se adaptar rapidamente e funcionar suavemente em diferentes ambientes. Oktopus é uma solução personalizada, como muitas ferramentas poderosas, sua implementação pode ser desafiadora. Neste blog, vamos explicar como implantá-lo na nuvem usando Docker-Compose.
                            Se você está procurando uma maneira simples de implantar o sistema sem dores de cabeça, este guia é para você.
                        </p>

                        <p>
                            <h4>Nota:</h4>
                            Neste post do blog, assumimos que você possui uma conta Microsoft com uma assinatura válida do Azure. Se não, sinta-se à vontade para se inscrever em uma no
                            <a href="https://azure.microsoft.com/en-us/pricing/purchase-options/azure-account?icid=azurefreeaccount"> portal do Azure </a>
                            onde você pode começar com créditos gratuitos para explorar e testar os serviços do Azure.
                        </p>

                        <h3>
                            Parte 1: Implantação da arquitetura geral
                        </h3>
                        <p>
                            A maneira mais fácil de instalar o Oktopus é em modo autônomo, com todos os componentes rodando na mesma máquina. No entanto, em um ambiente de produção, é recomendável implantar os MTPs (Broker MQTT, Websockets e servidores STOMP) e o ACS em servidores diferentes, além dos microserviços principais do Oktopus. Dessa forma, você pode escalar sua solução e ter um ambiente mais robusto e seguro.
                        </p>
                        <p>
                            O Oktopus Controller tem suporte ao <b>protocolo TR-069</b> com <b>ACS</b> e <b>todos os MTPs USP</b> também, embora neste blog vamos nos concentrar no padrão USP através do MQTT.
                        </p>
                        <p>
                            Nossa abordagem consiste em implantar os seguintes componentes do Oktopus: Controlador USP, broker MQTT e Agente USP (CPE).
                            Todas as partes serão detalhadas nos próximos parágrafos.
                        </p>
                        <div className="center">
                            <img src="/img/articles/younes-general-architecture-azure.png" className="maxImage" />
                        </div>

                        <h3>Parte 2: Provisionamento de infraestrutura</h3>
                        <h4>1. Criando VMs</h4>
                        <p>Para começar, primeiro criamos duas máquinas virtuais (VMs) Linux usando o portal do Azure. Certifique-se de que você tenha acesso total a essas máquinas, incluindo terminal SSH e privilégios de root. Se você encontrar alguma dificuldade durante a configuração, sinta-se à vontade para conferir este <a href="https://www.youtube.com/watch?v=KqLWQgcyJiA"> tutorial útil</a> para orientações passo a passo.</p>
                        <p>
                            Para os propósitos deste blog, estaremos executando nossos testes usando os seguintes planos de VM do Azure, que recomendamos para desempenho ideal:
                            <br />
                            <br />
                            <ul>
                                <li>- Servidor MQTT (VM1): plano B2s</li>
                                <li>- Oktopus (VM2): plano B1s</li>
                            </ul>
                        </p>

                        <h4>2. Instalando o Docker</h4>
                        <p>Instale o Docker e o Docker-Compose em ambas as VMs. O processo de instalação está bem documentado na <a href="https://docs.docker.com/engine/install/ubuntu/">documentação oficial do Docker</a>.</p>

                        <h4>3. Definindo regras de rede</h4>
                        <p>Como você sabe, o MQTT usa a porta 1883, mas não é permitido por padrão. Portanto, precisamos definir novas regras de porta em ambas as VMs.
                        </p>
                        <p>Escolha <i>VM1</i>-&gt; <i>Rede da VM</i> -&gt; <i>Regras de entrada</i> -&gt; <i>Adicionar regra de porta de entrada</i>.</p>

                        <div className="justifyCenter">
                            <table style={{ borderCollapse: "collapse", border: "1px solid black" }}>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Fonte</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Qualquer</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Intervalo de porta de origem</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>*</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Destino</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Qualquer</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Serviço</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Personalizado</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}><b>Intervalos de porta de destino</b></td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}><b>1883</b></td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Protocolo</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>TCP</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Ação</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Permitir</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Nome</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>"mqtt non TLS"</td>
                                </tr>
                            </table>
                        </div>

                        <p>Precisamos também criar a mesma regra de porta usando as mesmas características para conexões de saída.</p>
                        <p>
                            Escolha <i>VM1</i>-&gt; <i>Rede</i> -&gt; <i>Regras de saída</i> -&gt; <i>Adicionar regra de porta de saída</i>
                        </p>

                        <div className="justifyCenter">
                            <table style={{ borderCollapse: "collapse", border: "1px solid black" }}>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Fonte</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Qualquer</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Intervalo de porta de origem</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>*</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Destino</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Qualquer</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Serviço</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Personalizado</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}><b>Intervalos de porta de destino</b></td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}><b>1883</b></td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Protocolo</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>TCP</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Ação</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Permitir</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>Nome</td>
                                    <td style={{ border: "1px solid black", padding: "5px" }}>"mqtt non TLS"</td>
                                </tr>
                            </table>
                        </div>
                        <p>Para garantir que completamos os passos corretamente, tente a conexão SSH para ambas as VMs e execute este comando na VM1:</p>
                        <pre>
                            <code>
                                younes@BrokerMachine:~$ netstat -tulen
                            </code>
                        </pre>
                        <p>A saída deve ser semelhante a esta:</p>

                        <div className="center">
                            <img src="/img/articles/younes-output-mqtt.png" className="maxImage" />
                        </div>
                        <br></br>

                        <h4>4. Preparando o ambiente do Agente</h4>
                        <p>Como pode ser visto na seção de arquitetura, estaremos implantando o OBUSPA em três ambientes diferentes, mas vamos nos concentrar especificamente no ambiente WSL (Windows Subsystem for Linux). No entanto, você pode seguir os mesmos passos fornecidos neste guia para instalar o agente USP em qualquer outra distribuição Linux ou macOS.</p>
                        <p>Instalar o WSL é simples. Basta seguir as instruções fornecidas na <a href="https://learn.microsoft.com/en-us/windows/wsl/install">documentação oficial da Microsoft</a> para configurar o WSL em seu sistema.</p>

                        <h3>Parte 3: Implantação de diferentes componentes</h3>
                        <h4>1. Broker MQTT</h4>
                        <p>Para usar o Oktopus, precisamos implantar um broker MQTT desenvolvido sob medida. Este broker é baseado no servidor MQTT de código aberto Mochi, mas foi aprimorado para automatizar ações específicas por meio de hooks predefinidos (por exemplo, enviando tópicos que incluem o Agent EndpointID, cabeçalhos de pacotes MQTT personalizados, entre outras tarefas).</p>

                        <p>Para começar, basta puxar a imagem do Docker e seguir estes passos para iniciar o broker:</p>

                        <pre>
                            younes@BrokerMachine:~$ sudo docker pull oktopusp/mqtt
                        </pre>
                        <pre>
                            younes@BrokerMachine:~$ sudo docker run -d --name mqtt_container -p 1883:1883 oktopusp/mqtt
                        </pre>

                        <p>A saída deve ser semelhante a esta:</p>
                        <div className="center">
                            <img src="/img/articles/mqtt-container.png" className="maxImage" />
                        </div>
                        <br></br>
                        <h4>2. Controlador USP</h4>
                        <p>A próxima tarefa é instalar o Controlador Oktopus na VM2. Para isso, siga os passos abaixo:</p>

                        <pre>
                            <code>
                                root@younesHost:/home/younes# git clone <a href="https://github.com/OktopUSP/oktopus.git">https://github.com/OktopUSP/oktopus.git</a>
                            </code>
                        </pre>

                        <pre>
                            <code>
                                root@younesHost:/home/younes# cd oktopus/deploy/compose/
                            </code>
                        </pre>
                        <p>Para que o Oktopus conheça o servidor MQTT que acabamos de instalar na VM1, precisamos configurá-lo editando o valor da variável "MQTT_URL" definida em "deploy/compose/.env.mqtt-adapter". O valor será o endereço IP do servidor MQTT (VM1).</p>

                        <pre>
                            <code>
                                root@younesHost:/home/younes/oktopus/deploy/compose# nano .env.mqtt.adapter
                            </code>
                        </pre>
                        <pre>
                            <code>
                                MQTT_URL=tcp://&lt;vm1_ip_address&gt;:1883
                            </code>
                        </pre>

                        <p>O passo final é iniciá-lo:</p>

                        <pre>
                            <code>
                                root@younesHost:/home/younes/oktopus/deploy/compose# COMPOSE_PROFILES=nats,controller,mqtt,adapter,frontend docker compose up -d
                            </code>
                        </pre>

                        <p>Certifique-se de que completamos os passos corretamente. Tente este comando:</p>

                        <pre>
                            <code>
                                root@younesHost:/home/younes# docker ps -a
                            </code>
                        </pre>

                        <p>Você deve ver todos os contêineres do Oktopus listados com o status "Up".</p>
                        <h4>3. OBUSPA</h4>
                        <p>Como mencionado anteriormente, estaremos instalando o OBUSPA no WSL. Os passos completos estão detalhados no repositório oficial do Broadband Forum no GitHub, na seção <a href="https://github.com/BroadbandForum/obuspa/blob/master/QUICK_START_GUIDE.md">QUICK START GUIDE</a>.</p>

                        <p>
                            <h4>Nota:</h4>
                            Aqui instalamos o OBUSPA no nível do Sistema Operacional, mas também é possível executar o OBUSPA em um ambiente containerizado. O agente USP está encapsulado em um contêiner Docker fácil de usar. Você pode alterar o arquivo de redefinição de fábrica da mesma forma que faremos abaixo e, em seguida, executar o Agente conforme explicado na <a href="https://docs.oktopus.app.br/getting-started/installation/agent-simulator">documentação do Simulador de Agente Oktopus</a>.</p>

                        <p>O objetivo é garantir que o agente reconheça o controlador através da conexão MQTT. Para alcançar isso, precisamos modificar o modelo de dados do agente. Especificamente, estaremos editando o arquivo de redefinição de fábrica do OBUSPA. Há um modelo de configuração MQTT fornecido no GitHub, que você pode acessar e baixar <a href="https://github.com/OktopUSP/oktopus/blob/main/agent/oktopus-mqtt-obuspa.txt">aqui</a>.</p>

                        <p>Agora edite o arquivo adicionando o endereço IP público do Servidor MQTT:</p>
                        <pre>
                            <code>
                                Device.MQTT.Client.1.BrokerAddress "<span>&lt;endereço_ip_público_do_servidor_mqtt_vm1&gt;</span>"
                            </code>
                        </pre>

                        <p>Por favor, tenha cautela ao fazer alterações, pois editar outros valores pode causar problemas. Manuseie o arquivo com cuidado para evitar problemas inesperados.</p>

                        <h3>Parte 4: Resultados e Validação</h3>
                        <p>Parabéns por ter chegado até aqui! Agora é hora de ver os resultados de todo o seu trabalho árduo. Após acessar a interface web do Oktopus (ela usa o mesmo endereço IP público da VM2), crie um usuário e faça login.</p>
                        <p>Inicie o agente (no ambiente WSL) usando o arquivo de configuração que acabamos de editar. Você pode simplesmente usar este comando:</p>
                        <pre>
                            <code>
                                $ obuspa -p -v 4 -r oktopus-mqtt-obuspa.txt -i eth0
                            </code>
                        </pre>

                        <p>E aqui vamos nós. O agente está conectado com sucesso ao Oktopus:</p>
                        <div className="center">
                            <img src="/img/articles/mqtt-device-online.png" className="maxImage" />
                        </div>

                        <p>Após uma longa jornada, você agora completou todos os passos essenciais. Parabéns pela sua perseverança, é uma conquista significativa chegar a este ponto! Com tudo em seu lugar, o Controlador Oktopus agora está pronto para a ação, e você está equipado para aprimorar e otimizar sua funcionalidade conforme necessário.</p>
                        <p>Para cargas de trabalho de produção, há muitos detalhes e aspectos a considerar, mas os três principais assuntos não abordados neste blog são:
                            <ul>
                                <li>
                                    --&gt; Criptografia MQTT TLS.
                                </li>
                                <li>
                                    --&gt; TLS para a interface web do Oktopus.
                                </li>
                                <li>
                                    --&gt; Autenticação do agente USP ao MQTT.
                                </li>
                            </ul>
                            Esperamos abordar esses tópicos em um futuro post no blog.
                        </p>
                        <p>Mantenha-se atento a mais conteúdos sobre o Oktopus, o protocolo USP e como ele está ajudando empresas do setor de telecomunicações a crescerem e oferecerem mais e melhores serviços aos seus clientes.</p>
                        <h5>Escrito por:</h5>
                        <Younes lang={language} />
                        <br />
                        <span>Data: 15/11/2024</span>
                    </div>
                }
            </div>
        </section>
    )
};
