import React from "react";
import { useState } from "react";


export default function ScheduleMeeting () {

    const [loading, setLoading] = useState(true);

    return (
        <div id="schedule-meeting">
            <div className="container">
                {loading &&                     
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div className="loader"></div>
                </div>
                    }
                <iframe
                    title="schedule-meeting"
                    onLoad={() => {setLoading(false)}}
                    style={{ border: "0", width: "100%", height: "100%", frameborder: "0", scrolling: "no" }}
                    src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1uLG0zeWqMzhVM7LO-fNlMqrB34f-V0uXNL58NqFQ5K7bRnyce7bNBmadgP2isXY-Y-tSWeM1A?gv=true"
                >
                </iframe>
            </div>
        </div>
    )
}