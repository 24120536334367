import React from "react";
import { useLanguage } from "../context/language";
import SEO from "./SEO";

export default function Blog(props) {
  const { language } = useLanguage();

  return (
    <section id="blog">
      <SEO
        title="Oktopus | Blog"
        description={
          "Oktopus blog with information about Oktopus products, services, and technical information about USP, CWMP, and CPEs/IoTs management."
        }
        keywords="Blog, Oktopus"
      />
      <div className="container">
        <div className="row">
          <div className="about-text">
            <h2>Blog</h2>
          </div>
          {language === "en" ? (
            <div className="blog-cards">
              <div className="col-xs-12 col-md-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/younes-general-architecture-azure.png"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">USP Controller Deployment at Microsoft Azure Cloud</h5>
                    <p class="card-text">
                      Host Oktopus Controller in a Cloud environment using Microsoft Azure.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/oktopus-at-azure"
                        class="btn btn-primary"
                      >
                        Read
                      </a>
                      <small class="text-muted center">
                        Published on 15/11/2024
                      </small>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div style={{ textAlign: "center" }}>
                    <img
                      class="card-img-top"
                      src="/img/articles/oss.png"
                      alt="Card image cap"
                      width={"100%"}
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      {" "}
                      E2E Open Source Device Management Ecosystem
                    </h5>
                    <p class="card-text">
                      {" "}
                      Manage your devices from the Control Plane to the Edge
                      with a 100% Open Source stack.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/oss-device-management"
                        class="btn btn-primary"
                      >
                        Read
                      </a>
                      <small class="text-muted center">
                        Published on 09/09/2024
                      </small>
                    </div>
                  </div>
              </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/msgs-templates.png"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">USP/CWMP Messages Templates</h5>
                    <p class="card-text">
                    A range of possibilities opens up with the creation of customized messages for each use case.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/msgs-templates"
                        class="btn btn-primary"
                      >
                        Read
                      </a>
                      <small class="text-muted center">
                        Published on 31/10/2024
                      </small>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/oktopus-initial.png"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">What is the Oktopus Controller?</h5>
                    <p class="card-text">
                      Understand what is this software, what it solves, who is
                      it for, what it makes possible.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/what-is-oktopus"
                        class="btn btn-primary"
                      >
                        Read
                      </a>
                      <small class="text-muted center">
                        Published on 18/07/2024
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/ComplexConnections2.gif"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      How to scale to 1 Million devices
                    </h5>
                    <p class="card-text">
                      Discover how Oktopus is capable to handle more than one
                      million simulated USP devices simultaneously.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/1m-devices"
                        class="btn btn-primary"
                      >
                        Read
                      </a>
                      <small class="text-muted center">
                        Published on 16/10/2024
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xs-12 col-md-4">
                            <div class="card">
                                <img class="card-img-top" src="/img/tr069-diagram.png" alt="Card image cap" width={"100%"} />
                                <div class="card-body">
                                    <h5 class="card-title">1 million devices with TR-069</h5>
                                    <p class="card-text">Get to know how Oktopus CWMP solution is capable to handle more than one million simulated devices simultaneously being managed by our Open Source Controller.</p>
                                    <a href="#" class="btn btn-primary">Read</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <div class="card">
                                <img class="card-img-top" src="/img/no-image.png" alt="Card image cap" width={"100%"}/>
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary">Read</a>
                                </div>
                            </div>
                        </div> */}
            </div>
          ) : (
            <div className="blog-cards">
              <div className="col-xs-12 col-md-4">
                <div class="card">
                    <img
                      class="card-img-top"
                      src="/img/articles/younes-general-architecture-azure.png"
                      alt="Card image cap"
                      width={"100%"}
                    />
                    <div class="card-body">
                      <h5 class="card-title">Instalação de Controlador USP na nuvem</h5>
                      <p class="card-text">
                      Hospede o Oktopus Controller em um ambiente de nuvem usando o Microsoft Azure.
                      </p>
                      <div className="spacebetween">
                        <a
                          href="/blog/oktopus-at-azure"
                          class="btn btn-primary"
                        >
                          Read
                        </a>
                        <small class="text-muted center">
                          Published on 15/11/2024
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/oss.png"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      {" "}
                      Ecossistema Open Source para Gerência de Dispositivos{" "}
                    </h5>
                    <p class="card-text">
                      {" "}
                      Gerencie seus dispositivos do Control Plane até a ponta
                      com uma stack 100% Open Source.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/oss-device-management"
                        class="btn btn-primary"
                      >
                        Ler
                      </a>
                      <small class="text-muted center">
                        Publicado em 09/09/2024
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/msgs-templates.png"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">Template de Mensagens USP/CWMP</h5>
                    <p class="card-text">
                      Um leque de possiblidades se abre com a criação de mensagens customizadas para cada caso de uso.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/msgs-templates"
                        class="btn btn-primary"
                      >
                        Ler
                      </a>
                      <small class="text-muted center">
                        Publicado em 31/10/2024
                      </small>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/oktopus-initial.png"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">O que é o Controlador Oktopus?</h5>
                    <p class="card-text">
                      Entenda o que é este software, o que ele resolve, qual seu
                      público alvo, e o que possibilita.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/what-is-oktopus"
                        class="btn btn-primary"
                      >
                        Ler
                      </a>
                      <small class="text-muted center">
                        Publicado em 18/07/2024
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="/img/articles/ComplexConnections2.gif"
                    alt="Card image cap"
                    width={"100%"}
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      Como chegar a 1 milhão de dispositivos
                    </h5>
                    <p class="card-text">
                      Descubra como o Oktopus é capaz de lidar com mais de um
                      milhão de dispositivos USP simultaneamente.
                    </p>
                    <div className="spacebetween">
                      <a
                        href="/blog/1m-devices"
                        class="btn btn-primary"
                      >
                        Ler
                      </a>
                      <small class="text-muted center">
                        Publicado em 16/10/2024
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">

              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
